.container {
  display: flex;
  padding: 1px;
  padding-left: 2px;
  width: calc(100% - 2 * var(--outline-width));
  
  border-radius: 4px;
  outline: none;

  opacity: 0;
  pointer-events: none;

  transition: opacity var(--ui-trans-time);
}
.container.visible {
  opacity: 1;
  pointer-events: all;
}

.container,
.inner {
  --outline-width: 1px;

  margin: var(--outline-width);

  background-color: #2c0000;
  border: 1px solid #4b0800;
  border-left-width: 0;

  box-shadow: 0 0 0 var(--outline-width) #0f0000;
}

.inner {
  flex: 1;
  position: relative;

  border-radius: 3px;
}

.options {
  display: flex;
  flex-direction: column;

  padding: 3.5px 0;
}

.rivets {
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;
  pointer-events: none;

  > img {
    --size: 6px;
    --offset: 2.5px;

    position: absolute;
    width: var(--size);
    height: var(--size);
  }
  > img:nth-child(1) {
    top: var(--offset);
    left: var(--offset);
  }
  > img:nth-child(2) {
    top: var(--offset);
    right: var(--offset);
  }
  > img:nth-child(3) {
    bottom: var(--offset);
    left: var(--offset);
  }
  > img:nth-child(4) {
    bottom: var(--offset);
    right: var(--offset);
  }
}
