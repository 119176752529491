.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 35px 24px 26px !important;
}

.indent-tool {
  --size: 42px;
  --border-3d-width: 1px;
  --border-3d-light-color: #7A442C;
  --border-3d-dark-color: #552A17;

  position: absolute;
  height: var(--size);
  width: var(--size);
  left: 6px;
  padding: 5px;

  background: #65341E;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #5C2E1A;
}

.indent-tool-img-container {
  position: relative;
  height: 100%;
}

.labelled-indent-row {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  > .labelled-indent {
    flex: 0.435;
  }
}
