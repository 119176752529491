.container {
  --border-3d-width: 1.5px;
  --border-3d-light-color: #5A1C0A;
  --border-3d-dark-color: #3B0F07;

  padding: 1px;

  background-color: #4C190A;
  border-radius: 6px;
  outline: none;
}

.inner1 {
  --border-3d-width: 1px;
  --border-3d-light-color: #3B0F07;
  --border-3d-dark-color: #5A1C0A;

  display: flex;
  padding: 1px;

  background-color: #280000;
  border-radius: 3px;
}

.inner2 {
  --size: 34px;
  --border-3d-width: 1px;
  --border-3d-light-color: #555453;
  --border-3d-dark-color: #2F2F2F;
  --border-3d-width-active-offset: 1px;

  position: relative;
  height: var(--size);
  width: var(--size);
  padding: 3px;

  background-color: #424140;
  border-radius: 3px;

  transition:
    background-color var(--ui-trans-time),
    border-color var(--ui-trans-time);
}
.container:hover .inner2 {
  background-color: #4b4a49;
}
.container:active .inner2 {
  background-color: #424140;
}

.rivet {
  --offset: 1px;

  position: absolute;
}
.rivet:nth-child(1) {
  top: var(--offset);
  left: var(--offset);
}
.rivet:nth-child(2) {
  top: var(--offset);
  right: var(--offset);
}
.rivet:nth-child(3) {
  bottom: var(--offset);
  left: var(--offset);
}
.rivet:nth-child(4) {
  bottom: var(--offset);
  right: var(--offset);
}

.cross-container {
  position: relative;
  height: 100%;
  width: 100%;
}
