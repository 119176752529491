.container {
  position: absolute;
  height: 742px; /* PSD ref = 887px; */
  width: 1056px; /* PSD ref = 1262px; */
  background-image: url(../../../assets/images/game/modal-bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 103px 51px 47px;

  pointer-events: all;
}

.title {
  position: absolute;
  top: 25px;
  align-self: center;

  font-weight: 700;
  font-size: 42px;
  line-height: 45px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: white;

  user-select: none;
}

.close-button {
  position: absolute;
  top: 27px;
  right: 26px;
}
