.container {
  --background-border-width: 3px;
  --highlight-border-width: 4px;
  --highlight-gap: 1px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 63px;
  width: 60px;
  padding: calc(5px + var(--background-border-width));
  margin: calc(var(--highlight-gap) + var(--highlight-border-width));

  cursor: var(--cursor-pointer);

  img {
    position: relative;
    height: 100%;
    width: 100%;

    object-fit: contain;
  }

  /* quantity */
  span {
    position: absolute;
    right: calc(3px + var(--background-border-width));
    bottom: calc(1px + var(--background-border-width));;

    font-size: 14px;
    line-height: 15px;
    color: #F7F7F7;
    user-select: none;
  }
}

.background {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: transparent;
  border: var(--background-border-width) solid #4164D7;
  border-radius: 4px;
  pointer-events: none;
  transition: background-color var(--ui-trans-time);
}
input:hover ~ .background {
  background-color: rgba(255, 255, 255, 0.04);
}
input:active ~ .background {
  background-color: transparent;
}

.highlight {
  --offset: calc(-1 * (var(--highlight-gap) + var(--highlight-border-width)));

  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);

  border: var(--highlight-border-width) solid #EE4200;
  border-right-color: #862400;
  border-bottom-color: #862400;
  border-radius: 7.5px;

  z-index: 1;
  opacity: 0;
  pointer-events: none;

  transition: opacity var(--ui-trans-time);
}
input:checked ~ .highlight {
  opacity: 1;
}
