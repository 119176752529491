.container {
  --menu-scale: 0.82;

  display: flex;
  flex-direction: column;

  z-index: 0;
  overflow-y: scroll;

  background-color: transparent;

  opacity: 0;
  pointer-events: none;

  transition:
    background-color 700ms,
    opacity var(--ui-trans-time);
}
.container.visible {
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 1;
  pointer-events: all;
}

.inner {
  flex: 1;
  
  position: relative;
  display: flex;
  flex-direction: column;
}
