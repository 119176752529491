.container {
  --border-3d-width: 2px;
  --border-3d-light-color: #555453;
  --border-3d-dark-color: #2B2B2B;

  position: relative;
  padding: 4px;

  background: #424140;
  border-radius: 6px;
}
.container.outline {
  --outline-width: 2px;
  --outline-box-shadow: 0 0 0 var(--outline-width) #210707;

  margin: var(--outline-width);
  box-shadow: var(--outline-box-shadow);
}
.container.drop-shadow {
  box-shadow: var(--modal-drop-shadow);
}
.container.outline.drop-shadow {
  box-shadow:
    var(--outline-box-shadow),
    var(--modal-drop-shadow);
}

.container.gleam::before {
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-image: url(../../../../assets/images/game/gleam-white.png);
  background-position: center;
  background-size: 125%;
  border-radius: inherit;
  opacity: 0.12;
}
.container.bright-gleam::before {
  opacity: 0.59;
}
