.board-container {
  display: flex;
}

.left-board {
  flex: 0.36;
}

.left-board-panel {
  padding: 7px;
}

.img-indent {
  position: relative;
  padding: 9px;
  margin-bottom: -3px;

  background-color: #1D0000;
  border: 1px solid #442010;
  border-top-color: #7A442C;
}
/* inner border */
.img-indent::before {
  --outline-width: 1px;

  content: "";
  position: absolute;
  top: var(--outline-width);
  right: var(--outline-width);
  bottom: var(--outline-width);
  left: var(--outline-width);

  border: 1px solid #82482E;
  border-top-color: #532718;
  outline: var(--outline-width) solid #5D301D;
}

.img-panel {
  height: 279px;
  padding: 29px;
}

.img-container {
  position: relative;
  height: 100%;
}

.plaque {
  --side-offset: -10px;
  --border-3d-width: 2px;
  --border-3d-light-color: #555453;
  --border-3d-dark-color: #1F1E1E;
  --inner-border-width: 1px;

  position: relative;
  padding: var(--inner-border-width);
  height: 49px;
  
  /* account for additional box-shadow 'border' */
  margin-top: 1px;
  margin-left: calc(1px + var(--side-offset));
  margin-right: var(--side-offset);

  display: flex;
  justify-content: center;
  align-items: center;

  background: #4A4948;
  box-shadow: -0.5px -0.5px 0 0.5px #a2a1a0;

  /* rivet */
  > img {
    --size: 8px;
    --offset: 3px;
  }

  > span {
    position: relative;
    font-weight: 700;
    font-size: 29px;
    line-height: 31px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #111010;
    user-select: none;
  }
}
.plaque::before {
  content: "";
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  background-image: radial-gradient(circle at 1px 1px, #3E3C3A 1px, transparent 0);
  background-size: 3px 3px;

  border: var(--inner-border-width) solid #1F1E1E;
  border-right-color: #A2A1A0;
  border-bottom-color: #A2A1A0;
}

.right-board {
  flex: 0.64;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 17px 12px 19px !important;
}

.progress {
  margin-top: 14px;
}

.mint-button {
  height: 64px;
  width: 259px;
  margin-top: 11.5px;
}
