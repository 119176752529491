.container {
  --border-3d-width: 1px;
  --border-3d-light-color: #350C0C;
  --border-3d-dark-color: #601717;

  display: flex;

  border-radius: 7px;
}

.inner {
  --border-3d-width: 2px;
  --border-3d-light-color: #1E0606;
  --border-3d-dark-color: #350C0C;

  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 32px;
  min-width: 117px;
  padding: 0 4px;

  background: #2B0909;
  border-radius: 6px;

  > span {
    font-size: 16px;
    line-height: 17px;
    color: #B6B2AE;
    user-select: none;
  }
}

.img-container {
  position: relative;
  height: 18px;
  width: 22px;
  margin-right: 12px;
}
