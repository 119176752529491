.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin: 2.8px;
  cursor: var(--cursor-pointer);
}

.background {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  background-color: #5E311D;
  border: 2.5px solid #6F4F49;
  border-right-color: #462E2F;
  border-bottom-color: #462E2F;
  border-radius: 2px;
  box-shadow: 0 0 0 2.8px #2E1F1E;

  transition: background-color var(--ui-trans-time);
}
input:hover ~ .background {
  background-color: #683620;
}
input:active ~ .background {
  background-color: #5E311D;
}

.highlight {
  --offset: -2.5px;

  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);

  border: 5px solid #27C731;
  border-right-color: #15711A;
  border-bottom-color: #15711A;
  border-radius: 2px;

  z-index: 1;
  opacity: 0;
  pointer-events: none;

  transition: opacity var(--ui-trans-time);
}
input:checked ~ .highlight {
  opacity: 1;
}

.img-container {
  position: relative;
  height: 58px;
  width: 54px;
  padding: 3px 2px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #564041;
  border: 2.5px solid #6C5556;
  border-right-color: #462E2F;
  border-bottom-color: #462E2F;
  border-radius: 4px;
  box-shadow: 0 0 0 2.5px #A40CB8;
  margin: 2.5px;
}

.info-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  user-select: none;

  span {
    white-space: nowrap;
  }
  span:nth-child(1) {
    font-size: 22px;
    line-height: 25px;
    color: #CACBCF;
  }
  span:nth-child(2) {
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #A40CB8;
  }
  span:nth-child(3) {
    margin-top: 2px;

    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #EBB016;
  }
}
.container.order > .info-container > span:first-child {
  color: #C76F14;
}

.order-tag {
  --size: 45px;

  position: absolute;
  top: 0; right: 0;
  height: var(--size);
  width: var(--size);
}
