.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container.condensed {
  flex-direction: row;
  height: 46px;
}

.img-container {
  position: relative;
  height: 69px;
  max-height: 100%;
  width: 123px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: relative;
    max-height: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }
}
.container.condensed > .img-container {
  width: 58px;
}

.input-container {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;

  height: 46px;
  width: 100%;
  max-width: 212px;
  margin-top: 8px;

  input {
    position: relative;
    top: 0; left: 0;
    height: 100%; width: 100%;
    padding: 0 6px;

    background-color: transparent;
    border-radius: 3px;
    outline: none;

    font-weight: 700;
    font-size: 27px;
    line-height: 29px;
    color: #C76F14;

    transition: background-color var(--ui-trans-time);
  }
  input[readonly] {
    cursor: var(--cursor-default);
    user-select: none;
  }
  input:not([readonly]) {
    padding-right: 79px;
  }
}
.container.condensed > .input-container {
  margin-top: 0;
  margin-left: 3px;
  max-width: 143px;

  input {
    text-align: right;
  }
}

.input-container > input:hover:not([readonly]):not(:focus) {
  background-color: #390d0d;
}

.input-buttons {
  position: absolute;
  right: 5px;

  display: flex;

  background: #FF8118;
  border: 1px solid #89460E;
  border-radius: 4px;
  overflow: hidden;

  > button {
    margin: 0;
    padding: 0 4px;
    height: 33px;

    background-color: inherit;

    font-size: 19px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -0.01em;
    color: white;
    user-select: none;

    transition: background-color var(--ui-trans-time);
  }
  > button:last-child {
    border-left: inherit;
  }
  > button:hover {
    background-color: #f98f38;
  }
  > button:active {
    background-color: inherit;
  }
}
