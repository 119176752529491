.container {
  --border-width: 2px;
  --outline-width: 2px;

  --border-3d-width: var(--border-width);
  --border-3d-light-color: #2B0303;
  --border-3d-dark-color: #581616;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3px;
  margin: var(--outline-width);
  height: calc(194px - 2 * var(--outline-width));
  width: 100%;
  max-width: calc(162.5px - 2 * var(--outline-width));

  background: #210707;
  border-radius: 3px;
  box-shadow: 0 0 0 var(--outline-width) #400B0B;

  cursor: var(--cursor-pointer);
}

.inner {
  --border-3d-width: 2px;
  --border-3d-light-color: #6F3A22;
  --border-3d-dark-color: #3B1909;
  --border-3d-width-active-offset: 1px;
  --inset-border-width: 2.5px;

  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: calc(var(--inset-border-width) + 8px);

  background-color: #210707;
  border-radius: 4px;

  transition: all var(--ui-trans-time);

  span {
    margin-top: 10px;
    font-weight: 700;
    font-size: 29px;
    line-height: 31px;
    /* identical to box height */
    text-transform: uppercase;
    text-align: center;
    color: #CACBCF;
    user-select: none;
  }
}
input:hover ~ .inner {
  background-color: #2b0909;
}
input:active ~ .inner {
  background-color: #210707;
}

.img-container {
  flex: 1;
  position: relative;
}

.inner-border {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  border: var(--inset-border-width) solid #522815;
  border-radius: 2px;

  pointer-events: none;
}

.highlight {
  --offset: calc(-1 * (var(--border-width) + var(--outline-width)));

  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);

  border-radius: inherit;
  box-shadow: 0 0 0 2.4px #C76F14;

  opacity: 0;
  pointer-events: none;

  transition: opacity var(--ui-trans-time);
}
input:checked ~ .highlight {
  opacity: 1;
}
