.container {
  outline: none;
}

.inner {
  display: flex;
  align-items: center;

  padding: 7.5px 16px;

  transition: background-color var(--ui-trans-time);

  > span {
    margin-left: 21.5px;

    font-size: 21px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    color: #924E03;
    user-select: none;

    transition: color var(--ui-trans-time);
  }
}
.container:hover > .inner {
  background-color: rgba(255 255 255 / 0.03);

  > span {
    color: #E98B22;
  }
}
.container:active > .inner {
  background-color: transparent;

  > span {
    color: #924E03;
  }
}

.img-container {
  align-self: stretch;
  position: relative;
  width: 28px;
}
