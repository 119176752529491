.container {
  --hud-bar-top-size: 52px;
  --hud-bar-bottom-size: 45px;

  display: flex;
  flex-direction: column;
  
  cursor: var(--cursor-default);
}

.map {
  
}

button,
input[type=button],
input[type=submit] {
  cursor: var(--cursor-pointer);
}

input[type=text],
input[type=password],
input[type=number] {
  cursor: var(--cursor-text);
}

img {
  pointer-events: none;
  user-select: none;
}
