.container {
  --floating-border-offset: -3px;

  position: relative;
  padding: 3px;
  
  /* Account for floating border created by pseudo element */
  margin: calc(-1 * var(--floating-border-offset) - 1px);
  margin-top: 9px;

  background: #250000;
  border: 1px solid #82482E;
  border-radius: 4px;
}
.container::after {
  content: '';
  position: absolute;
  top: var(--floating-border-offset);
  right: var(--floating-border-offset);
  bottom: var(--floating-border-offset);
  left: var(--floating-border-offset);

  border: 1px solid #442010;
  border-radius: 6px;

  pointer-events: none;
}

.tool-buttons {
  display: flex;
  height: 39px;
  padding: 2px;

  background: #552C19;
  border-radius: 3px;
}

.tool-button {
  --border-radius: 3px;
  --outline-width: 1px;

  flex: 1;
  margin: var(--outline-width);

  cursor: var(--cursor-pointer);
}
.tool-button:not(:first-child) {
  margin-left: calc(var(--outline-width) + 1px);
}

.tool-button-inner {
  --border-3d-width: 1px;
  --border-3d-light-color: #82482E;
  --border-3d-dark-color: #30180E;
  --border-3d-width-active-offset: 1px;

  height: 100%;
  padding: 3px;

  background-color: #5E311D;
  box-shadow: 0 0 0 var(--outline-width) #221008;

  transition:
    background-color var(--ui-trans-time),
    border-color var(--ui-trans-time);
}
.tool-button:first-child > .tool-button-inner {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.tool-button:last-child > .tool-button-inner {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
input:hover ~ .tool-button-inner {
  background-color: #693620;
}
input:active ~ .tool-button-inner {
  background-color: #5E311D;
}
input:checked ~ .tool-button-inner {
  background-color: #30180E;
  border-color: transparent;
}

.tool-button-img-container {
  position: relative;
  height: 100%;

  img {
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.tier-buttons {
  --material-button-margin: 1.5px;

  display: flex;
  flex-wrap: wrap;

  margin:
    calc(5px - var(--material-button-margin))
    calc(3px - var(--material-button-margin))
    calc(6px - var(--material-button-margin));
}

.material-button {
  margin: var(--material-button-margin);
  width: calc(50% - 2 * var(--material-button-margin));
}

.material-button-inner {
  --border-3d-width: 2px;
  --border-3d-light-color: #6F4F49;
  --border-3d-dark-color: #4A271D;
  --border-3d-width-active-offset: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;

  background-color: #5E311D;
  cursor: var(--cursor-pointer);

  transition:
    background-color var(--ui-trans-time),
    border-color var(--ui-trans-time);

  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: #C76F14;

    user-select: none;
  }
}
input:disabled ~ .material-button-inner {
  cursor: var(--cursor-not-allowed);

  > span {
    opacity: 0.43;
  }
}
input:not(:disabled):hover ~ .material-button-inner {
  background-color: #693721;
}
input:not(:disabled):active ~ .material-button-inner {
  background-color: #5E311D;
}
input:not(:disabled):checked ~ .material-button-inner {
  --border-3d-light-color: #1E0E08;
  --border-3d-dark-color: #512C1C;

  background-color: #3B1E11;
  border-width: 1px;
}
