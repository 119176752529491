@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Mecha";
  src: local("Mecha"), url("./assets/fonts/Mecha.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Mecha Bold";
  src: local("Mecha Bold"), url("./assets/fonts/Mecha_Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Mecha Condensed";
  src: local("Mecha Condensed"), url("./assets/fonts/Mecha_Condensed.ttf") format("truetype");
}
@font-face {
  font-family: "Mecha Condensed Bold";
  src: local("Mecha Condensed Bold"), url("./assets/fonts/Mecha_Condensed_Bold.ttf") format("truetype");
}

html {
  --cursor-default: url(./assets/images/game/cursor/sword.png), default;
  --cursor-default-alt: url(./assets/images/game/cursor/sword-sparkling.png) 4 6, default;
  --cursor-pointer: url(./assets/images/game/cursor/gauntlet.png), pointer;
  --cursor-text: url(./assets/images/game/cursor/quill.png) 0 45, text;
  --cursor-not-allowed: url(./assets/images/game/cursor/sun-blocked.png) 16 18, not-allowed;

  --ui-trans-time: 100ms;
  --modal-drop-shadow: 0 0 33px rgb(0 0 0 / 42%);
}

body {
  font-family: 'Mecha';
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* ——— Scrollbar ——— */

.scrollbar {
  --scrollbar-thickness: 20px;
  --scrollbar-foreground: #121212;
  --scrollbar-background: transparent;/* #2a0000;*/
  --scrollbar-padding: 2px;
  
  /* Foreground, Background */
  scrollbar-color:
    var(--scrollbar-foreground)
    var(--scrollbar-background);
}
.scrollbar::-webkit-scrollbar {
  width: var(--scrollbar-thickness); /* Mostly for vertical scrollbars */
  height: var(--scrollbar-thickness); /* Mostly for horizontal scrollbars */
}
.scrollbar::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--scrollbar-foreground);
  border: var(--scrollbar-padding) solid var(--scrollbar-background);
  border-radius: 5px;
  background-clip: padding-box;
  box-shadow:
    inset -1.5px -1.5px 0 #2B2B2B,
    inset 0 0 0 1.5px #555453,
    inset 0 0 0 3px #424140,
    inset -2px -2px 0 2px #555453,
    inset 0 0 0 4px #2B2B2B;
}
.scrollbar::-webkit-scrollbar-track { /* Background */
  background: var(--scrollbar-background);
}
.scrollbar::-webkit-scrollbar-button:start:vertical:decrement, /* Buttons */
.scrollbar::-webkit-scrollbar-button:end:vertical:increment {
  --scrollbar-thumb-size: 17px;

  height: var(--scrollbar-thumb-size);
  width: var(--scrollbar-thumb-size);

  background-color: var(--scrollbar-background);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: var(--scrollbar-padding) solid var(--scrollbar-background);
} 
.scrollbar::-webkit-scrollbar-button:start:vertical:decrement {
  background-image: url('./assets/images/game/scrollbar/thumb-up.png');
  border-bottom: none;
} 
.scrollbar::-webkit-scrollbar-button:end:vertical:increment {
  background-image: url('./assets/images/game/scrollbar/thumb-down.png');
  border-top: none;
}

.no-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.no-scrollbar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


/* ——— 3D border ——— */

.border-3d {
  border: var(--border-3d-width) solid var(--border-3d-light-color);
  border-right-color: var(--border-3d-dark-color);
  border-bottom-color: var(--border-3d-dark-color);
}
button:not(:disabled):active.border-3d:not(.no-click),
button:not(:disabled):active .border-3d:not(.no-click),
input:not(:disabled):active ~ .border-3d:not(.no-click) {
  --border-3d-width-larger: calc(var(--border-3d-width) + var(--border-3d-width-active-offset));
  --border-3d-width-smaller: calc(var(--border-3d-width) - var(--border-3d-width-active-offset));

  border: var(--border-3d-width-larger) solid var(--border-3d-dark-color);
  border-right-color: var(--border-3d-light-color);
  border-bottom-color: var(--border-3d-light-color);
  border-right-width: var(--border-3d-width-smaller);
  border-bottom-width: var(--border-3d-width-smaller);
}


/* ——— No spin button ——— */

input[type=number].no-spin-btn::-webkit-outer-spin-button,
input[type=number].no-spin-btn::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number].no-spin-btn {
    -moz-appearance:textfield; /* Firefox */
}


/* ——— Contained ——— */

.contained {
  --offset: 0px;
  position: absolute;
  top: var(--offset);
  left: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
}
img.contained {
  object-fit: contain;
}


/* ——— Shadowed img container ——— */

.shadowed-img-container {
  --shadow-length: 10px;
  --shadow-color: #1D0000;

  /* Update dimensions for shadow */
  margin-right: var(--shadow-length);
  margin-bottom: var(--shadow-length);
}
.shadowed-img-container > img {
  filter: drop-shadow(
    var(--shadow-length)
    var(--shadow-length)
    0
    var(--shadow-color));
}
