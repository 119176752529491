.container {
  --bg-default-color: #3d9618;
  --bg-hover-color: #48a620;
  --edge-light-color: #69c442;
  --edge-dark-color: #267703;
  --shadow-color: #102000;

  --raised: 2px;
  --max-depress: 2px;

  font-weight: 700;
  font-size: 38px;
  line-height: 40px;
}

.button {
  --depress: 0px;
  --shadow: calc(var(--raised) - var(--depress));

  width: calc(100% - var(--raised));
  height: calc(100% - var(--raised));

  margin-right: var(--shadow);
  margin-bottom: var(--shadow);
  margin-top: var(--depress);
  margin-left: var(--depress);
  padding: 12px;

  background-color: var(--bg-default-color);
  border: 2px solid var(--edge-light-color);
  border-bottom-color: var(--edge-dark-color);
  border-right-color: var(--edge-dark-color);
  border-radius: 6px;
  outline: none;
  box-shadow: var(--shadow) var(--shadow) 0 var(--shadow-color);
  
  user-select: none;
  transition: all var(--ui-trans-time);
}
.container--secondary > .button {
  --bg-default-color: #C15317;
  --bg-hover-color: #d55b19;
  --edge-light-color: #F17F41;
  --edge-dark-color: #9F3902;
  --shadow-color: #30180D;
}
.container--tertiary > .button {
  --bg-default-color: #A75E23;
  --bg-hover-color: #bc6c2b;
  --edge-light-color: #D68A4D;
  --edge-dark-color: #88440E;
  --shadow-color: #30180D;
}
.container--mint > .button {
  --bg-default-color: #337CB7;
  --bg-hover-color: #3b8cce;
  --edge-light-color: #5EAAE7;
  --edge-dark-color: #1C6096;
  --shadow-color: #30180D;
}
.button:disabled {
  --bg-default-color: #414141;
  --bg-hover-color: #414141;
  --edge-light-color: #555453;
  --edge-dark-color: #2d2d2d;

  color: #656565;
  cursor: var(--cursor-not-allowed);
}
.button:hover {
  background-color: var(--bg-hover-color);
}
.button:active:not(:disabled) {
  --depress: var(--max-depress);
  background-color: var(--bg-default-color);
}

/* button element cannot flex */
.button-inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  text-transform: uppercase;
}
