.board-container {
  flex: 1;
  display: flex;
}

.character-board {
  flex: 1;
}

.character-board-panel {
  display: flex;
  padding: 26.5px;
}

.img-container {
  flex: 1;
  position: relative;
}

.info-board {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 26.5px 13px 29.5px;
  margin-left: calc(16px + var(--outline-width, 0px));
}

.value-panel {
  display: flex;
  flex-direction: column;
  padding: 40px 19px 26px !important;
}

.value-field {
  display: flex;
  flex-direction: column;
}
.value-field:not(:first-of-type) {
  margin-top: 27px;
}

.indent {
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  span {
    position: relative;
    user-select: none;
  }
  span:first-of-type {
    font-weight: 700;
    font-size: 27px;
    line-height: 29px;
    color: #663539;
  }
  span:last-of-type {
    font-weight: 700;
    font-size: 27px;
    line-height: 29px;
    color: #C76F14;
  }
}

.value-field > span {
  margin-top: 9px;

  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  text-align: center;
  color: #BB9A7E;
  
  user-select: none;
}

.progress-panel {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 19px 22px;
  margin-top: calc(30px + var(--outline-width, 0px)) !important;
}

.progress {

}

.mint-button {
  height: 64px;
  width: 259px;
  margin-top: 21px;
}
