.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .filters {
    display: flex;
  }

  .filter:not(:first-child) {
    margin-left: 1px;
  }
  .filter:last-child {
    border-top-right-radius: 5px;
  }

  .balance {
    display: flex;
    flex-direction: column;
    width: 134px;
    padding: 1.5px 5px;
    user-select: none;

    background: #424140;
    border: 2px solid #555453;
    border-right-color: #2B2B2B;
    border-bottom-color: #2B2B2B;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    label {
      font-size: 10px;
      line-height: 11px;
      letter-spacing: -0.01em;
      color: #F7F7F7;
      cursor: var(--cursor-default);
    }

    .balance-display {
      display: flex;
      align-items: center;
      padding: 0 2.5px;
      padding-right: 3.5px;
      margin-top: 2px;

      background: #151414;

      span {
        flex: 1;

        font-size: 15px;
        line-height: 16px;
        color: #D9C211;
      }

      img {
        width: 17px;
      }
    }
  }
}

.items {
  flex: 1;
  position: relative;
  margin-top: 2px;
  z-index: 10;
}

.items-inner {
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;

  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;

  padding: 1px;
  overflow-y: auto;

  background: #250000;
  border-radius: 2px;
}
.items-inner--simple {
  padding: 1px 5px;
  padding-left: 12px;
}

.items-inner > .item {
  --margin: 3.3px;
  flex: 1;
  margin: var(--margin) !important;
  max-width: calc(50% - 2 * var(--margin));
}

.items-inner > .item-simple {
  margin: 9px !important;
}