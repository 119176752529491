.container {
  height: 186px;
  width: 494px;

  display: flex;
  flex-direction: column;
}

.panel {
  flex: 1;
  display: flex;
  padding: 2px;
}

.option-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 31px;
}
.option-panel:first-of-type {
  margin-right: 6px;
}
.option-panel:last-of-type {
  margin-left: 7px;
}

.button {
  height: 60px;
  
  font-size: 39px;
  line-height: 41px;
  letter-spacing: 0.02em;
  color: white;
}
