.board {
  display: flex;
}

.right {
  flex: 0.67;
  display: flex;
  flex-direction: column;
  margin-left: 7.5px;
  height: 100%;
}

.plate {
  display: flex;
  margin-top: calc(var(--outline-width, 0px) + 3px) !important;
  min-height: 140px;
}

.plate-separator {
  background-color: #1F1E1E;
  border-left: 1px solid #2B2B2B;
  border-right: 1px solid #555453;
  margin: -4px 3.5px;
}

.order-button {
  --shadow-color: #292929 !important;
  
  flex: 0.16;
  margin-left: 3px;
  min-width: 80px;
}
.order-button.buy {
  color: #90be7e !important;
}
.order-button.sell {
  color: #faa88c !important;
}
