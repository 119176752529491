.container {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 70px 110px;

  background-size: 100% 100%;
  background-repeat: no-repeat;

  z-index: 1;
}
.container.only-hooks {
  visibility: hidden;
}

.hooks {
  visibility: visible;

  > div {
    position: absolute;

    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  > div:first-child {
    background-image: url(../../../../../../assets/images/game/hud/menu/hook-left.png);
  }
  > div:last-child {
    background-image: url(../../../../../../assets/images/game/hud/menu/hook-right.png);
  }
}
.container.hide-hooks > .hooks {
  visibility: hidden;
}

.hook-anchors {

  > div {
    position: absolute;
  }
}

.hang-anchors {

  > div {
    position: absolute;
    bottom: 3%;
  }
}

.play-button {
  position: absolute !important;
  transform: rotate(-0.5deg) scale(var(--menu-scale)) !important;
  bottom: -2.7%;
}
