.container {
  margin-top: 11px;
}

.plate {
  display: flex;
  flex-direction: column;
  padding: 14px !important;
}

.panel {
  display: flex;

  box-shadow:
    var(--outline-box-shadow, none),
    0 calc(var(--outline-width, 0px) + 1.5px) 0 #2E2C2A !important;
}
.panel:first-child {
  height: 76.5px;
  align-items: center;
}
.panel:last-child {
  height: 151px;
  margin-top: 9px;
}

.quantity-box {

}
.panel:first-child > .quantity-box {
  flex: 0.3125;
}
.panel:last-child > .quantity-box {
  flex: 0.362;
}

.separator {
  align-self: stretch;
  width: 3px;
  margin: -1px 8px -2px;

  background: #181412;
  border-right: 1px solid #7A4229;
  border-left: 1px solid #46200F;
}

.exchange-rate {
  flex: 0.375;
  display: flex;
  justify-content: center;
  height: 46px;
}

.exchange-rate-inner {
  --border-3d-width: 2px;
  --border-3d-light-color: #481200;
  --border-3d-dark-color: #7F3D12;
  --outline-width: 1px;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 280.5px;

  background: #6B330E;
  border-radius: 5px;
  box-shadow: 0 0 0 var(--outline-width) #040000;
  margin: var(--outline-width);
  padding: 0 10px;

  font-weight: 700;
  font-size: 27px;
  line-height: 29px;
  text-transform: uppercase;
  white-space: nowrap;
  color: #FDFDFC;

  user-select: none;

  span {
    color: #C76F14;
  }
}

.center-buttons {
  flex: 0.276;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-button {
  height: 61px;
  width: 100%;
  max-width: 159px;
}
.center-button:first-child {
  img {
    width: 77px;
  }
}
.center-button:last-child {
  margin-top: 4px;
  
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 30px !important;
  letter-spacing: 0.11em !important;
  color: white !important;
}
