.container {
  flex: 0.33;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px !important;
}

.header {
  --border-3d-width: 1.8px;
  --border-3d-light-color: #7A442C;
  --border-3d-dark-color: #462010;

  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  margin-top: 1px;

  span {
    font-weight: 700;
    font-size: 19px;
    line-height: 20px;
    text-transform: uppercase;
    color: #C76F14;
    user-select: none;
  }
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 4px 3px;
  max-height: 100%;
  margin: 2px 2px 0;

  background: #250000;
  border-radius: 2px;
}

.empty-button {
  --border-3d-width: 2px;
  --border-3d-light-color: #573731;
  --border-3d-dark-color: #320F05;
  --border-3d-width-active-offset: 1px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px;
  margin: 1px;

  background: #461905;
  box-shadow: 0 0 0 1px #150605;
  transition: all var(--ui-trans-time);

  span {
    margin-top: 1px;

    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: 0.03em;
    color: #80849E;
    user-select: none;
  }
}
.empty-button:hover {
  background: #541e06;
}
.empty-button:active {
  background: #461905;
}
