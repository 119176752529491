.container {
  position: relative;
  top: var(--hud-bar-top-size);
  height: calc(100% - var(--hud-bar-top-size) - var(--hud-bar-bottom-size));
  overflow: hidden;
}
.container.cover {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;

  background-image: url(../../../assets/images/game/map-bg.gif);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.container.cover > .inner {
  --width: calc(100vw / var(--scale, 1));

  top: auto;
  width: var(--width);
  height: max(calc(108/192 * var(--width)), 100%);
}

.button {
  position: absolute;
  cursor: var(--cursor-default-alt);
  outline: none;
}
.button.castle {
  width: 26.8%;
  height: 53%;
  top: 0;
  right: 0;

  border-bottom-left-radius: 50%;
  border-top-left-radius: 46%;
  border-bottom-right-radius: 33%;
}
.button.river {
  width: 19.5%;
  height: 120%;
  top: 0;
  left: 34.5%;
  transform: rotate(48deg);
  border-radius: 20%;
}
.button.market {
  width: 31%;
  height: 45%;
  bottom: 0.9%;
  right: 3.4%;
  border-radius: 42%;
}
.button.mine {
  width: 10%;
  height: 29.91%;
  top: 1%;
  left: 0;

  border-bottom-right-radius: 50%;
  border-top-right-radius: 60%;
}
.button.log-house {
  top: 2.962962963%;
  left: 40.3%;
  height: 15.9%;
  width: 11.1%;
  border-radius: 45%;
}
.button.barracks {
  top: 0.55%;
  left: 18.28125%;
  height: 18%;
  width: 16.3%;

  border-top-right-radius: 40%;
  border-bottom-left-radius: 20%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 45%;
}
.button.farm {
  top: 17.5%;
  left: 13%;
  height: 27%;
  width: 21.5%;

  border-radius: 40%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 35%;
}
.button.dock {
  bottom: 19.3518518519%;
  left: 0;
  height: 31.6666666667%;
  width: 10.7291666667%;

  border-top-right-radius: 50%;
  border-bottom-right-radius: 35%;
}

.glow {
  position: absolute;
}

.glow.castle {
  top: 0; right: 0;
  height: 53.1481481481%;
  width: 26.8229166667%;
}
.button.castle:hover ~ .glow.castle {
  opacity: 1;
}

.glow.river {
  top: 0; left: 0;
  height: 100%;
  width: 82.8125%;
}
.button.river:hover ~ .glow.river {
  opacity: 1;
}

.glow.market {
  bottom: 1.41574074074%;
  right: 2.66041666667%;
  height: 46.5740740741%;
  width: 31.71875%;
}
.button.market:hover ~ .glow.market {
  opacity: 1;
}

.glow.mine {
  left: 0;
  top: 1.2962962963%;
  height: 28.4259259259%;
  width: 9.375%;
}
.button.mine:hover ~ .glow.mine {
  opacity: 1;
}

.glow.log-house {
  top: 2.96296296296%;
  left: 39.9479166667%;
  height: 15.8333333333%;
  width: 10.8333333333%;
}
.button.log-house:hover ~ .glow.log-house {
  opacity: 1;
}

.glow.barracks {
  top: 0.55555555555%;
  left: 18.28125%;
  height: 17.6851851852%;
  width: 16.40625%;
}
.button.barracks:hover ~ .glow.barracks {
  opacity: 1;
}

.glow.farm {
  top: 14.537037037%;
  left: 12.9166666667%;
  height: 30.2777777778%;
  width: 20.8333333333%;
}
.button.farm:hover ~ .glow.farm {
  opacity: 1;
}

.glow.dock {
  bottom: 19.3518518519%;
  left: 0;
  height: 31.6666666667%;
  width: 10.7291666667%;
}
.button.dock:hover ~ .glow.dock {
  opacity: 1;
}
