.board {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 31px;
}

.option:last-child {
  margin-left: 41px;
}
