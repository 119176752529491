.container {

}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: 23px 95px 20px;
}

.title {
  font-size: 28px;
  line-height: 30px;
  text-transform: uppercase;
  color: #C76F14;
}

.img-container {
  position: relative;
  height: 188px;
  width: 233px;
  margin-top: 22px;
}
