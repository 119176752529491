.container {
  --cell-spacing: 9px;

  width: calc(100% + 2 * var(--cell-spacing));
  margin: calc(-1 * var(--cell-spacing));
  border-spacing: var(--cell-spacing);
  border-collapse: separate;
  table-layout: fixed;
}

.header {


  th {
    font-size: 26px;
    line-height: 25px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #C76F14;
    user-select: none;
  }
  th:first-child {
    width: 64px;
  }
}

.row {


  td {
    height: 46px;
    padding: 0;
  }
}

.indent {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    position: relative;

    font-size: 27px;
    line-height: 25px;
    text-align: right;
    color: #C76F14;
    user-select: none;
  }
}
td:first-child > .indent {
  padding: 5px 7px;
}
td:not(:first-child) > .indent {
  padding: 0 9px;
}

.img-container {
  position: relative;
  height: 100%;
  width: 100%;
}
