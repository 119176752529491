.container {
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  pointer-events: none;
}

.top-bar,
.bottom-bar {
  position: relative;
}

.top-bar {
  z-index: 1;
}

.menu {
  flex: 1;
}

.bottom-bar {

}
