.container {
  display: flex;
  flex-direction: column;

  > span {
    align-self: center;
    margin-top: 3.5px;

    font-weight: 700;
    font-size: 21px;
    line-height: 22px;
    color: #DBDBDB;
    user-select: none;
  }
}

.indent {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    position: relative;

    font-weight: 700;
    font-size: 38px;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: #C76F14;
    user-select: none;
  }
}
