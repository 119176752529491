.container {
  padding: 7px;
  padding-bottom: 23px;

  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  margin: 0 2px;
}

.tab {
  flex: 1;
  cursor: var(--cursor-pointer);
}
.tab:not(:first-child) {
  margin-left: 2px;
}

.tab-inner {
  --border-3d-width: 2px;
  --border-3d-light-color: #7A442C;
  --border-3d-dark-color: #462010;
  --border-3d-width-active-offset: 1px;

  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  background-color: #68351E;

  transition:
    background-color var(--ui-trans-time),
    border-color var(--ui-trans-time);

  > span {
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    letter-spacing: 0.01em;
    white-space: nowrap;
    color: #EBEBED;
    user-select: none;
  }
}
input:hover ~ .tab-inner {
  background-color: #723b21;
}
input:active ~ .tab-inner {
  background-color: #68351E;
}
input:checked ~ .tab-inner {
  background-color: #30180E;
  border: 1px solid #221008;

  > span {
    /* color: #8084A0; */
  }
}

.list {
  flex: 1;
  position: relative;
  height: 300px;
  margin-top: 3px;

  border-radius: 4px;
}

.list-inner {
  --border-3d-width: 1px;
  --border-3d-light-color: #442010;
  --border-3d-dark-color: #82482E;

  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  padding: 4px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background: #250000;
  border-radius: inherit;
}

.worker:not(:first-child) {
  margin-top: 3px;
}
