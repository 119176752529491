.container {

}

.panel {
  padding: 35px 82px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-container {
  --shadow-color: #260808;
  
  position: relative;
  height: 100%;
  width: 100%;

  opacity: 1;
  transition: opacity var(--ui-trans-time);
}
.container:hover .img-container {
  opacity: 0;
}

.info-container {
  --margin-y: 16.5px;
  --margin-x: 13px;

  position: absolute;
  padding: 13px;
  height: calc(100% - 2 * var(--margin-y));
  width: calc(100% - 2 * var(--margin-x));

  background: #260808;
  border: 1px solid #7A442C;
  border-radius: 6px;

  opacity: 0;
  transition: opacity var(--ui-trans-time);
}
.container:hover .info-container {
  opacity: 1;
}

.info-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  padding: 34px !important;
  padding-bottom: 21px !important;

  p {
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #CD947D;
    user-select: none;
  }

  span {
    margin-top: 33px;

    font-size: 28px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: #C76F14;
    user-select: none;
  }
}

.mint-button {
  height: 51px;
  width: 100%;
  max-width: 241px;
  margin-top: 41px;
  
  font-size: 34px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #FCF4EB;
}
