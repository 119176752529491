.container {
  --size: 7px;
  --offset: 2px;

  position: absolute;
  height: var(--size);
  width: var(--size);
}
.container:nth-of-type(1) {
  top: var(--offset);
  left: var(--offset);
}
.container:nth-of-type(2) {
  top: var(--offset);
  right: var(--offset);
}
.container:nth-of-type(3) {
  bottom: var(--offset);
  right: var(--offset);
}
.container:nth-of-type(4) {
  bottom: var(--offset);
  left: var(--offset);
}
