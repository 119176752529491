.container {
  display: flex;
  flex-direction: column;

  background: #210303;
  border-bottom: 2px solid #6d1717;

  pointer-events: all;

  > hr {
    margin-bottom: 3px;

    border: none;
    border-top: 1px solid #6d1717;
  }
}

.inner {
  display: flex;
  justify-content: space-between;

  padding-left: 16px;
  padding-right: 25px;
}

.left,
.right {
  flex: 1;
  display: flex;
  align-items: center;
}

.left {
  
}

.button {

}
.button:not(:first-child) {
  margin-left: calc(var(--outline-width, 0px) + 13px);
}

.center {
  --height: calc(8px + var(--hud-bar-top-size));

  position: relative;
  height: var(--height);
  width: calc(6.421875 * var(--height));
  
  display: flex;
  flex-direction: column;
  align-items: center;

  /* bottom should protrude out of bounds slightly */
  margin-bottom: -14px;
  z-index: 1;

  background-image: url(../../../../assets/images/game/hud/protruding-panel.png);
  background-size: 100% 100%;
}

.logo-container {
  position: absolute;
  height: 88px;
  width: 68.126520681%;
  pointer-events: none;
}

.right {
  justify-content: flex-end;
}

.indent {
  
}
.indent:not(:first-child) {
  margin-left: 3px;
}
