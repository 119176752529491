.container {
  /* Set stacking context */
  z-index: 0;
  
  opacity: 0;
  pointer-events: none;
  
  transition: opacity 0.2s;
}
.container.visible {
  opacity: 1;
  pointer-events: all;
}
