.container {
  --outline-width: 2px;
  --border-width: 2px;
  --inner-border-width: 1px;

  position: relative;
  height: 46px;
  width: 56px;
  padding: calc(var(--inner-border-width) + 3px);
  margin: var(--outline-width);

  background-color: #250000;
  border: var(--border-width) solid #724D2E;
  border-radius: 2px;
  box-shadow: 0 0 0 var(--outline-width) #261819;

  transition: background-color var(--ui-trans-time);
}
input:hover ~ .container {
  background-color: #2f0000;
}
input:active ~ .container {
  background-color: #250000;
}

.container::before {
  content: "";
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  border: var(--inner-border-width) solid #BB8057;
  border-right-color: #945B30;
  border-bottom-color: #945B30;
}

.corner {
  --size: 10px;
  --offset: calc(-1 * var(--border-width) - 1px);

  position: absolute;
  height: var(--size);
  width: var(--size);
}
.corner:nth-of-type(1) {
  top: var(--offset);
  left: var(--offset);
}
.corner:nth-of-type(2) {
  top: var(--offset);
  right: var(--offset);
}
.corner:nth-of-type(3) {
  bottom: var(--offset);
  right: var(--offset);
}
.corner:nth-of-type(4) {
  bottom: var(--offset);
  left: var(--offset);
}

.checkmark-container {
  position: relative;
  height: 100%;

  opacity: 0;
  transition: opacity var(--ui-trans-time);
}
input:checked ~ .container > .checkmark-container {
  opacity: 1;
}
