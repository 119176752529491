.container {
  display: flex;
  justify-content: center;
  align-items: center;

  > svg:last-child {
    position: absolute;
  }
}

.path {
  stroke-dasharray: 614;
  stroke-dashoffset: 614;
}
.container.animate .path {
  animation: fill 1s linear forwards;
}

@keyframes fill {
  from {
    stroke-dashoffset: 614;
  }
  to {
    stroke-dashoffset: 1228;
  }
}
