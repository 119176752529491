.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 35px;
  padding-left: 11px;
  padding-right: 8px;

  > span {
    position: relative;
    font-size: 23px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #80849E;
    user-select: none;
  }
}

.img-container {
  position: relative;
  height: 23px;
  width: 31px;
}
