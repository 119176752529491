.container {
  flex-shrink: 0;

  position: relative;
  height: 90px;
  width: 391px;

  transform: scale(var(--menu-scale));

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(../../../../../../../assets/images/game/hud/menu/sword-button/bg.png);
  background-size: 100% 100%;
}

.button {
  --max-shadow-length: 1px;
  --shadow-length: var(--max-shadow-length);

  position: relative;
  height: 55px;
  width: 265px;
  margin-bottom: var(--shadow-length);

  /* fix vertical center alignment in parent */
  margin-top: calc(2px + var(--max-shadow-length) - var(--shadow-length));

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid black;
  border-radius: 8px;

  box-shadow: 0 var(--shadow-length) 0 #804108;
}
.button:active {
  --shadow-length: 0px;
}

.button::before {
  content: '';
  position: absolute;
  top: 0; left: 0;
  height: 5px;
  width: 237px;
  opacity: 0.65;

  background-image: url(../../../../../../../assets/images/game/hud/menu/sword-button/shine.png);
  background-size: 100% 100%;

  z-index: 1;

  transition: opacity var(--ui-trans-time);
}
.button:hover::before {
  opacity: 1;
}
.button:active::before {
  opacity: 0.32;
}

.fire-ring {
  position: absolute;
}

.inner-1 {
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;

  border: 3px solid #FF3D2C;
  border-bottom-color: #8E1502;
  border-right-color: #8E1502;
  border-radius: 6px;
}

.inner-2 {
  position: relative;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(../../../../../../../assets/images/game/hud/menu/sword-button/fg-normal.jpg);
  background-position: center;
  border: 1px solid #1C0000;
  border-radius: 3px;

  z-index: 1;

  transition:
    border-color var(--ui-trans-time),
    background-image var(--ui-trans-time);

  > span {
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: white;

    transition: color var(--ui-trans-time);
  }
}
.button:hover .inner-2 {
  background-image: url(../../../../../../../assets/images/game/hud/menu/sword-button/fg-hover.jpg);
  border-color: white;
}
.button:active .inner-2 {
  background-image: url(../../../../../../../assets/images/game/hud/menu/sword-button/fg-active.jpg);
  border-color: black;

  > span {
    color: #C75959;
  }
}
