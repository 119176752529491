.container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.15);
  cursor: var(--cursor-default);
  opacity: 0;
  pointer-events: none;

  transition: opacity var(--ui-trans-time) ease-out;
}
.container--visible {
  opacity: 1;
  pointer-events: all;
}
.container--non-blocking {
  background-color: transparent;
  pointer-events: none;
}
/* Even for a non-blocking modal, if it is visible, 
children (i.e. modal itself, not the blocker) should be clickable. */
.container--non-blocking.container--visible > * {
  pointer-events: all;
}
