.container {
  min-width: 453px;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px; 
}

.title {
  font-size: 28px;
  line-height: 30px;
  text-transform: uppercase;
  color: #C76F14;
  user-select: none;
}

.text {
  margin: 9px 0;

  font-size: 23px;
  line-height: 27px;
  text-align: center;
  color: #A69E96;
  user-select: none;
}

.img-container {
  position: relative;
  height: 113px;
  width: 260px;

  /* overlap with the button */
  margin-bottom: -13px;

  > img {
    filter: drop-shadow(0 4px 0 #25130D);
  }
}
.container.fail .img-container {
  height: 64px;
  margin-top: 25px;
  margin-bottom: 18px;
}

.button {
  position: relative;
  height: 40px;
  min-width: 178px;

  font-size: 28px !important;
  line-height: 30px !important;
  color: white !important;
}
