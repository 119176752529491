.container {
  width: calc(1257px * var(--menu-scale));
  height: calc(831px * var(--menu-scale));

  padding: 125px 140px !important;

  background-image: url(../../../../../../assets/images/game/hud/menu/boards/tentacle.png);
}

.hooks {

  > div {
    height: 5.89651022864%;
    width: 9.6260938743%;
    top: 2.52707581227%;
  }
  > div:first-child {
    left: 13.0469371519%;
  }
  > div:last-child {
    right: 14.2402545744%;
  }
}

.hook-anchors {

  > div {
    top: 5.6%;
  }
  > div:first-child {
    left: 17.7406523469%;
  }
  > div:last-child {
    right: 18.7748607796%;
  }
}

.hang-anchors {

  > div {
    position: absolute;
    bottom: 5%;
  }
}
