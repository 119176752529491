.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  --border-3d-width: 2px;
  --border-3d-light-color: #555453;
  --border-3d-dark-color: #2B2B2B;
  --border-3d-width-active-offset: 1px;
  --outline-width: 2px;

  height: 80px;
  width: 93px;
  margin: var(--outline-width);

  background-color: #250000;
  border-radius: 10px;
  box-shadow: 0 0 0 var(--outline-width) #210707;
  outline: none;

  transition:
    background-color var(--ui-trans-time),
    border-color var(--ui-trans-time);
}
.container.small > button {
  height: 54px;
  width: 64px;
}
.button:disabled {
  cursor: var(--cursor-not-allowed);
}
.button:not(:disabled):hover {
  background-color: #2d0000;
}
.button:not(:disabled):active {
  background-color: #250000;
}

.button-inner {
  --border-3d-width: 2px;
  --border-3d-light-color: #180000;
  --border-3d-dark-color: #330808;
  --outline-width: 2px;

  margin: var(--outline-width);
  height: calc(100% - 2 * var(--outline-width));
  width: calc(100% - 2 * var(--outline-width));

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  box-shadow: 0 0 0 var(--outline-width) #794226;
}

.img-container {
  --size: 60px;

  position: relative;
  height: var(--size);
  width: var(--size);
}
.container.small .img-container {
  --size: 39px;
}
.container.empty .img-container {
  --size: 37px;
}
.container.blocked .img-container {
  --size: 34px;
}

.label {
  margin-top: 3px;

  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  letter-spacing: 0.17em;
  text-transform: uppercase;
  text-shadow:
    -1px -1px 0 #5D2D18,
    1px 1px 0 #7A442C;
  color: #2D0E00;
  user-select: none;
}
.container.small > .label {
  margin-top: 0;
}
