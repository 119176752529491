.container {
  display: flex;
  flex-direction: column;
}

.box {
  flex: 1;
  padding: 40px 24px 27px !important;
  margin-top: calc(7px + var(--outline-width, 0px)) !important;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.labelled-indent > span {
  margin-top: 7px;
  font-weight: 700;
  font-size: 27px;
  line-height: 29px;
  text-transform: uppercase;
  color: #ADADAD;
}
.labelled-indent:not(:first-child) {
  margin-top: 31px;
}

.buttons {
  margin: 15px 9px 0;
}

.button {
  height: 61px;

  font-weight: 700;
  font-size: 39px;
  line-height: 41px;
  letter-spacing: 0.11em;
  color: white;
}
.button:not(:first-child) {
  margin-top: 13px;
}
