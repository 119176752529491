.container {
  display: flex;
  flex-direction: column;
}

.box-container {
  flex: 1;
  position: relative;
  margin-top: 7px;
}

.box {
  height: 100%;
}
.box:last-child {
  position: absolute;
  top: 0; left: 0;
  width: calc(100% - 2 * var(--outline-width, 0px));
}

.boost-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 9px 11px 0;
}

.stake-button {
  margin: 12px 8px 0;
  height: 62px;

  font-weight: 700;
  font-size: 39px;
  line-height: 41px;
  letter-spacing: 0.11em;
  color: white;
}
