.container {
  flex: 0.59;
  display: flex;
  flex-direction: column;
  user-select: none;

  hr {
    margin-top: 6px;
    margin-bottom: 2px;
    border-top: 1px solid #37190C;
    border-bottom: 1px solid #7A442C;
  }
}

.empty-plate {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    --border-3d-width: 1.5px;
    --border-3d-light-color: #161515;
    --border-3d-dark-color: #393636;

    position: relative;
    height: 33.5px;
    padding: 0 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #2B2B2B;
    border-radius: 7px;
    user-select: none;

    font-size: 23px;
    line-height: 24px;
    color: #818181;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px 0 10px;
}

.header-info {
  display: flex;
  align-items: baseline;

  /* Name */
  span:first-child {
    font-size: 23px;
    line-height: 24px;
    letter-spacing: -0.02em;
    white-space: nowrap;
    color: #CACBCF;
  }
  /* Rarity */
  span:last-child {
    margin-left: 12px;

    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #A40CB8;
  }
}

.header-meta {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.stars {
  display: flex;
}

.star {
  --size: 23px;

  height: var(--size);
  width: var(--size);
  object-fit: contain;
}

.info-button {
  --size: 11px;

  height: var(--size);
  width: var(--size);
  margin-left: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #141414;
  border: 1px solid #2A2A2A;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;

  img {
    height: 7px;
    width: 2.5px;
  }
}

.stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 21px 7px 24px;
 
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;

  th:not(:first-child),
  td:not(:first-child) {
    padding-left: 22px;
  }
  td {
    padding-top: 3px;
  }
}
