.container {
  max-height: 100%;
  max-width: 100%;
  
  background-image: url(../../../../assets/images/game/board-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 7px;
  padding: 11px;
}
