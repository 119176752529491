.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  user-select: none;
}

.text {
  display: flex;
  align-items: baseline;
  
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  color: #BB9A7E;

  .indent {
    height: 35px;
    width: 117px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      position: relative;

      font-weight: 700;
      font-size: 27px;
      line-height: 29px;
      color: #C76F14;
    }
  }
}

.bar {
  display: flex;
  height: 31px;
  padding: 4px;
  margin-top: 16px;
}

.notch {
  --border-3d-width: 1px;
  --border-3d-light-color: #E1801B;
  --border-3d-dark-color: #8E5214;

  position: relative;
  width: 8px;

  background: #C76F14;
  opacity: 0;
}
.notch--visible {
  opacity: 1;
}
.notch:nth-child(2) {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.notch:not(:nth-child(2)) {
  margin-left: 1px;
}
.notch:nth-child(41) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bar-overlay {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;

  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    text-align: center;
    color: white;
  }
}
