.inner {
  flex: 1;
  display: flex;
}

.board {
  flex: 1;
  display: flex;
}

.panel:first-child {
  flex: 0.52;
  margin-right: 7px;
}
.panel:last-child {
  flex: 0.48;
  padding: 15px 21px 24px !important;
  margin-left: 7px;
}
