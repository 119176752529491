.container {
  position: relative;
  height: 36px;
  width: 47px;

  cursor: var(--cursor-pointer);
}

.inner {
  --border-3d-width: 2px;
  --border-3d-light-color: #7A442C;
  --border-3d-dark-color: #462010;
  --border-3d-width-active-offset: 1px;

  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border-radius: inherit;

  transition:
    background-color var(--ui-trans-time),
    border-color var(--ui-trans-time);
}
input:hover ~ .inner {
  background: rgba(255, 255, 255, 0.04);
}
input:active ~ .inner {
  background: transparent;
}
input:checked ~ .inner {
  background: #30180E;
  border-color: #221008 !important;
  border-width: 1px;
}

.img-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 35px;
  max-height: 23px;
}
