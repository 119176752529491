.container {
  display: flex;

  /* account for box shadow border */
  margin-bottom: 1px;
  
  box-shadow: 0 1px 0 0 #250000;
}

.plate {
  --border-3d-width: 1px;
  --border-3d-light-color: #555453;
  --border-3d-dark-color: #1A1919;

  display: flex;
  align-items: center;
  padding: 3px 4px 4px;

  background: #424140;

  span {
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #C76F14;
    user-select: none;
  }
}

.indent {
  flex-shrink: 0;
  background: #424140;
}
.indent:first-child {
  padding: 3px;

  border-radius: 5px;

  > .indent-inner {
    --border-3d-width: 1px;
    --border-3d-light-color: #210707;
    --border-3d-dark-color: #210707;

    padding: 0 7px;

    background: #2F170D;
  }
}
.indent:last-child {
  padding: 1.5px;

  border-left: none;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  /* hack merge with left indent */
  margin-left: -2px;
  padding-left: 0;

  > .indent-inner {
    --border-3d-width: 1px;
    --border-3d-light-color: #1A1919;
    --border-3d-dark-color: #555453;

    position: relative;
    display: flex;
    justify-content: center;
    padding: 1px;

    background: #250000;

    > span {
      align-self: center;
      position: absolute;
      
      color: #FCF4EC;
    }
  }
}

.indent-inner {
  display: flex;
  height: 26px;

  border-radius: 3px;

  > span {
    user-select: none;
  }
}

.notches {
  display: flex;

  /* notch */
  > div {
    --border-3d-width: 1px;
    --border-3d-light-color: #E1801B;
    --border-3d-dark-color: #8E5214;
    --border-radius: 2px;

    width: 8px;

    background: #C76F14;
  }
  > div:first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  > div:last-child {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  > div:not(:first-child) {
    margin-left: 1px;
  }
}

.button {
  --border-3d-width: 1px;
  --border-3d-light-color: #27A115;
  --border-3d-dark-color: #15590B;
  --border-3d-width-active-offset: 1px;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #279018;

  transition:
    border-color var(--ui-trans-time),
    background-color var(--ui-trans-time);

  > span {
    text-transform: uppercase;
    color: #62C154;
    user-select: none;
  }
  > span:first-child {
    font-size: 16px;
    line-height: 17px;
    letter-spacing: -0.02em;
  }
  > span:last-child {
    margin-top: -4px;

    font-size: 22px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #62C154;
  }
}
.button:disabled {
  --border-3d-light-color: #555453;
  --border-3d-dark-color: #1a1919;

  cursor: var(--cursor-not-allowed);
  background-color: #414141;

  > span {
    color: #555453;
  }
}
.button:not(:disabled):hover {
  background-color: #2a9f1b;
}
.button:not(:disabled):active {
  background-color: #279018;
}
