.container {

}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 27px 43px !important;
}

.indent {
  position: relative;
  height: 153px;
  width: 274px;
  padding: 5px 6px;

  background: #2F0C08;
  border: 1px solid #7A442C;
  border-radius: 6px;
}

.img-container {
  position: relative;
  height: 100%;
  width: 100%;

  img {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
  }
}

.rivets {
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;

  > img {
    --offset: 11px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 19.5px;

  p {
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #CD947D;
    user-select: none;
  }
  p:not(:first-child) {
    margin-top: 12px;
  }
}

.button {
  height: 51px;
  width: 241px;
  margin-top: 21px;
  
  font-size: 34px !important;
  line-height: 25px !important;
  letter-spacing: -0.01em !important;
  color: white !important;
}
