.container {
  padding: 9px 16px 8px !important;
}

.list {
  --outline-width: 1px;

  position: relative;
  height: 100%;
  margin: var(--outline-width);

  border: 1px solid #7A442C;
  box-shadow: 0 0 0 var(--outline-width) #291107;
}

.list-inner {
  --tool-margin: 2px;
  --background: #250000;
  --scrollbar-background: var(--background);

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
  padding: 2px;
}

.tool {
  --size: 80px;

  height: var(--size);
  width: calc(25% - 2 * var(--tool-margin));
  margin: var(--tool-margin);

  background-color: var(--background);
  border: 1px solid #361107;
  outline: none;

  transition: background-color var(--ui-trans-time);
}
.tool:hover {
  background-color: #330000;
}
.tool:active {
  background-color: var(--background);
}

.tool-inner {
  height: 100%;
  padding: 10px;
}

.img-container {
  position: relative;
  height: 100%;
}
