.container {
  /* Set stacking context */
  z-index: 0;
  
  /* Links */
  > img {
    position: absolute;
  }
  /* Side */
  > img:nth-child(odd) {
    --height: calc(75px * var(--menu-scale));

    height: var(--height);
    width: calc(0.29333333333 * var(--height));

    z-index: 1;
  }
  /* Front */
  > img:nth-child(even) {
    --height: calc(78px * var(--menu-scale));

    height: var(--height);
    width: calc(0.74358974359 * var(--height));
  }
}
