.container {
  display: flex;
}

.panel {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 16px 28px;
}

.toggle {

}
.toggle:not(:last-child) {
  margin-right: calc(19px + var(--outline-width, 0px)) !important;
}
