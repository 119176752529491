.container {


  span {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #C76F14;
    user-select: none;
  }
}

.title {

}

.panel {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 15px;
  margin-top: calc(8px + var(--outline-width, 0px)) !important;
}

.table {
  table-layout: fixed;
  width: 100%;
  margin-top: 15px;

  tr:last-child > td {
    padding-top: 11px;
  }

  td {
    padding: 0;
  }
  td:first-child {


    > .indent {
      padding-left: 7px;
      padding-right: 10px;
      justify-content: space-between;
      align-items: center;

      > span:first-of-type {
        margin-left: 19px;

        font-size: 45px;
        line-height: 48px;
        color: #C76F14;
      }
      > span:last-of-type {
        flex: 1;

        font-size: 35px;
        line-height: 37px;
        letter-spacing: 0.05em;
        text-align: right;
        color: #80849E;
      }
    }
  }
  td:last-child {
    width: 80px;
    padding-left: 3px;

    > .indent {
      justify-content: center;

      > span {
        font-size: 35px;
        line-height: 37px;
        letter-spacing: 0.05em;
        color: #921310;
      }
    }
  }
}

.indent {
  height: 49px;
  display: flex;
  align-items: center;

  > span {
    position: relative;
  }
}

.indent-img-container {
  flex-shrink: 0;
  position: relative;
  height: 38px;
  width: 58px;
}

.panel-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
}
