.container {

  .title {
    font-size: 28px;
    line-height: 30px;
    text-transform: uppercase;
    color: #C76F14;
    user-select: none;
  }
}

.panel {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 18px 58px 20px;
  margin-top: calc(8px + var(--outline-width, 0px)) !important;

  > .title {
    text-transform: none;
  }
}

.resource-indents {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: 11px;
}

.resource-indent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 50px;
  padding: 6px 9px;

  > .img-container {
    align-self: stretch;
    position: relative;
    width: 59px;
  }

  > span {
    position: relative;
    
    font-size: 28px;
    line-height: 30px;
    color: #80849E;
  }
}
.resource-indent:last-of-type {
  margin-top: 10px;
}

.swap-arrow {
  --offset: -28px;

  position: absolute;
  height: 80.5px;
}
.swap-arrow:first-of-type {
  left: var(--offset);
}
.swap-arrow:last-of-type {
  right: var(--offset);
  transform: scaleX(-1);
}

.tax-indent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 16px 6px;
  margin-top: 6px;

  > span {
    position: relative;

    font-size: 18px;
    line-height: 19px;
    text-transform: uppercase;
    color: #980709;
  }
}

.container.tax-free .tax-indent {
  justify-content: center;

  > span {
    color: #826F6F;
  }
}
