.form {
  display: flex;
  flex-direction: column;

  > input:not(:first-of-type) {
    margin-top: 5px;
  }

  > .footer {
    display: flex;
    margin-top: 7px;

    > :last-child {
      margin-left: 5px;
    }
  }
}

.heading {
  margin-bottom: 10px;
  font-size: 24px;
}
