.container {
  position: relative;
  padding: 5px;
  padding-bottom: 6px;
  max-height: 100%;
  
  background-color: #562b18; /* average of image colour */
  background-image: url('../../../../assets/images/game/panel/bg.jpg');
  border: 2px solid #7A442C;
  border-right-color: #46200F;
  border-bottom-color: #46200F;
  border-radius: 9px;

  opacity: 0;
  pointer-events: none;
  
  transition: opacity var(--ui-trans-time);
}
.container.outline {
  --outline-width: 2px;
  --outline-color: #200404;
  --outline-box-shadow: 0 0 0 var(--outline-width) var(--outline-color);

  margin: var(--outline-width);
  max-height: calc(100% - 2 * var(--outline-width));
  box-shadow: var(--outline-box-shadow);
}
.container.grey-outline {
  --outline-color: #181412;
}
.container.visible {
  opacity: 1;
  pointer-events: all;
}

/* Used for a very subtle, inner, offset border */
.container.border::before {
  --inset: 3px;

  content: "";
  position: absolute;
  top: var(--inset);
  right: var(--inset);
  bottom: var(--inset);
  left: var(--inset);
  pointer-events: none;

  border: 1px solid #7A442C;
  opacity: 0.8;
}
.container.thin-border::before {
  --inset: 1.5px;
  border-radius: 5px;
}

.gleam {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-image: url(../../../../assets/images/game/gleam-brown.png);
  background-position: center;
  background-size: 140%;
  border-radius: inherit;
  opacity: 0.5;
}
.gleam--bright {
  opacity: 0.74;
}

.corner {
  --size: 23.5px;
  --offset: -2px;

  position: absolute;
  height: var(--size);
  width: var(--size);
  z-index: 1;
}
.corner:nth-of-type(1) {
  top: var(--offset);
  left: var(--offset);
}
.corner:nth-of-type(2) {
  top: var(--offset);
  right: var(--offset);
}
.corner:nth-of-type(3) {
  bottom: var(--offset);
  right: var(--offset);
}
.corner:nth-of-type(4) {
  bottom: var(--offset);
  left: var(--offset);
}

.rivets {
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;

  pointer-events: none;

  > img {
    --size: 8px;
    --offset: 7px;
  }
}
