.container {
  display: flex;
  padding: 1px;
  padding-left: 2px;
  
  border-radius: 4px;
  outline: none;
}

.container,
.inner {
  --outline-width: 1px;

  margin: var(--outline-width);

  background-color: #2c0000;
  border: 1px solid #4b0800;
  border-left-width: 0;

  box-shadow: 0 0 0 var(--outline-width) #0f0000;
}

.inner {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 26px;
  min-width: 199px;
  padding-left: 6px;
  padding-right: 4px;

  border-radius: 3px;

  transition:
    background-color var(--ui-trans-time),
    border-color var(--ui-trans-time);

  > span {
    flex: 1;
    
    font-size: 21px;
    line-height: 22px;
    white-space: nowrap;
    color: #E98B22;
    user-select: none;
  }
}
.container.uppercase span {
  text-transform: uppercase;
}
.container:hover > .inner {
  background-color: #350000;
}
.container:active > .inner {
  background-color: #2c0000;
  border-top-width: 2px;
  border-bottom-width: 0;
  border-left-width: 1px;
  border-right-width: 0;
  border-color: #1e0606;
}

.img-container {
  align-self: stretch;
  position: relative;
  width: 27px;
  margin-right: 13px;
}
