.container {
  min-height: 413px;
  width: 472px;
}

.panel {
  display: flex;
  flex-direction: column;
  padding: 17.5px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  font-weight: 700;
  font-size: 42px;
  line-height: 45px;
  text-align: center;
  color: white;
  user-select: none;
}

.buttons {
  display: flex;
  margin: 11px;
}

.button {
  flex: 1;
  height: 55px;
  
  font-weight: 700;
  font-size: 37px;
  line-height: 39px;
  text-align: center;
  white-space: nowrap;
  color: #F1EEEE;
}
.button:first-child {
  margin-right: 7px;
}
.button:last-child {
  margin-left: 7px;
}
