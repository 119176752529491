.container {
  flex: 0.25;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 9.5px 7px;
}

.field {
  display: flex;
  flex-direction: column;
  
  label {
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    /* identical to box height */
    letter-spacing: 0.03em;
    color: #D9D9D9;
    user-select: none;
    cursor: var(--cursor-default);
  }

  .input-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 2px;

    border: 1px solid #282828;
    border-right-color: #4E4E4E;
    border-bottom-color: #4E4E4E;
    border-radius: 5px;
  }

  .inc-buttons {
    position: absolute;
    left: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      font-size: 7px;
      line-height: 7.5px;
      color: #FF8118;
    }
  }

  input {
    flex: 1;
    padding-left: 14.5px;
    padding-right: 24px;
    width: 0;
    height: 19px;

    background-color: #0B0B0B;
    border: 1px solid #020202;
    border-right-color: #121212;
    border-bottom-color: #121212;
    border-radius: 4px;
    outline: none;

    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height */
    letter-spacing: 0.06em;
    text-align: center;
    color: #DF9D1A;

    transition: background-color var(--ui-trans-time);
  }
  input[readonly] {
    cursor: var(--cursor-default);
    user-select: none;
  }
  input:not([readonly]):not(:focus):hover {
    background-color: #111111;
  }

  .max-button {
    position: absolute;
    right: 4px;

    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    /* identical to box height */
    letter-spacing: 0.06em;

    color: #FF8118;
    opacity: 0.3;

    transition: opacity var(--ui-trans-time);
  }
  .max-button:hover {
    opacity: 0.5;
  }
}
.field:not(:first-child) {
  margin-top: 2px;
}
