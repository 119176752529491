.container {
  --border-3d-width: 1px;
  --border-3d-light-color: #210707;
  --border-3d-dark-color: #754029;
  --inner-border-3d-width: 3px;

  position: relative;
  padding: var(--inner-border-3d-width);

  border-radius: 8px;
  overflow: hidden;
}
.container--black {
  --border-3d-light-color: #46200F;
  --border-3d-dark-color: #7A442C;
}

.inner {
  --border-3d-width: var(--inner-border-3d-width);
  --border-3d-light-color: #1E0606;
  --border-3d-dark-color: #350C0C;

  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  background-color: #2B0909;
  border-radius: 6px;
}
.container--black > .inner {
  --border-3d-light-color: #0D0D0D;
  --border-3d-dark-color: #181818;

  background-color: #131313;
}
