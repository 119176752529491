.container {
  --border-3d-width: 2px;
  --border-3d-light-color: #6F4F49;
  --border-3d-dark-color: #4A271D;

  display: flex;
  align-items: center;
  padding: 1px 4px 2px;
  padding-right: 11px;

  background: #5E311D;
  border-radius: 2px;
  cursor: var(--cursor-pointer);
}
.container.alt-color {
  --border-3d-dark-color: #472D2C;
  background-color: #564040;
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 9px;

  > span {
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #80849E;
    user-select: none;
  }
}

.level,
.stat {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 33px;
  padding: 2px 7px;
  margin-top: 4px;

  background: #250000;
  border-radius: 5px;
}

.level > span {
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #C76F14;
  user-select: none;
}

.stat-img-container {
  --size: 24px;

  position: relative;
  height: var(--size);
  width: var(--size);
}

.stat > span {
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  letter-spacing: 0.12em;
  color: #80849E;
  user-select: none;
}

.checkbox {
  flex-shrink: 0;
  margin-left: 27px !important;
}
