.container {
  width: calc(1186px * var(--menu-scale));
  height: calc(802px * var(--menu-scale));

  padding: 115px 115px !important;

  background-image: url(../../../../../../assets/images/game/hud/menu/boards/wood.png);
}

.hooks {

  > div {
    height: 6.10972568579%;
    width: 10.2023608769%;
  }
  > div:first-child {
    top: 1.12219451372%;
    left: 10.455311973%;
  }
  > div:last-child {
    top: 1.49625935162%;
    right: 12.563237774%;
  }
}

.hook-anchors {

  > div:first-child {
    top: 4.1%;
    left: 15.4300168634%;
  }
  > div:last-child {
    top: 4.5%;
    right: 17.5379426644%;
  }
}
