.container {
  --border-width: 0px;
  --outline-width: 1.5px;
  --outline-box-shadow: 0 0 0 var(--outline-width) #555453;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: var(--outline-width);

  border: var(--border-width) solid #210707;
  border-radius: 9px;

  box-shadow: var(--outline-box-shadow);
}
.container.drop-shadow {
  box-shadow:
    var(--outline-box-shadow),
    var(--modal-drop-shadow);
}
.container.has-title {
  --border-width: 2px;

  width: 400px;
}

.title-container {
  --border-3d-width: 1.5px;

  height: 76px;

  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  span {
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #A75E23;
    user-select: none;
  }
}
.title-container--blue {
  --border-3d-light-color: #077581;
  --border-3d-dark-color: #043136;

  background-image: url(../../../../assets/images/game/panel-board/title-bg-blue.png);
}
.title-container--red {
  --border-3d-light-color: #A11D1C;
  --border-3d-dark-color: #520807;

  background-image: url(../../../../assets/images/game/panel-board/title-bg-red.png);
}

.inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10.5px 15px;
}
.container.has-title > .inner {
  padding: 6px;
  background: #260808;
  border: 1px solid #351A0E;
}

.panel {
  flex: 1;
  border-radius: 0 !important;
}

.corner {
  --size: 52px;
  --offset: calc(-1 * var(--border-width));

  position: absolute;
  height: var(--size);
  width: var(--size);
}
.corner:nth-of-type(1) {
  top: var(--offset);
  left: var(--offset);
}
.corner:nth-of-type(2) {
  top: var(--offset);
  right: var(--offset);
}
.corner:nth-of-type(3) {
  bottom: var(--offset);
  right: var(--offset);
}
.corner:nth-of-type(4) {
  bottom: var(--offset);
  left: var(--offset);
}
