.container {
  width: calc(1186px * var(--menu-scale));
  height: calc(741px * var(--menu-scale));

  background-image: url(../../../../../../assets/images/game/hud/menu/boards/blood.png);
}

.hooks {

  > div {
    height: 6.61268556005%;
    width: 10.2023608769%;
  }
  > div:first-child {
    top: -6.5%;
    left: 10.455311973%;
  }
  > div:last-child {
    top: -6.1%;
    right: 12.563237774%;
  }
}

.hook-anchors {

  > div:first-child {
    top: -3.3%;
    left: 15.4300168634%;
  }
  > div:last-child {
    top: -2.8%;
    right: 17.5379426644%;
  }
}
