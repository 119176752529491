.container {
  
}

.panel {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  max-width: 339px;
}

.header {
  display: flex;
  align-items: center;
  margin-top: 6px;

  > hr {
    flex: 1;
    border-top: 1px solid #250000;
    border-bottom: 1px solid #7A442C;
  }

  > span {
    margin: 0 10px;

    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    text-align: center;
    color: #C76F14;
    user-select: none;
  }
}

.indents {
  --indent-margin: 2px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  /* offset indent margin on top and bottom, double on sides */
  margin:
    calc(8px - var(--indent-margin))
    var(--indent-margin)
    calc(-1 * var(--indent-margin));
}

.indent {
  margin: var(--indent-margin);
  width: calc(50% - 2 * var(--indent-margin));
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 26px;
  margin-top: 7px;
}

.button {

}
