.container {
  position: relative;

  text-transform: uppercase;

  > h2 {
    font-size: 30px;
    text-align: center;
  }

  > span {
    font-size: 23px;
  }

  > img {
    position: absolute;
    width: 350px;
    top: 30%;
  }
}
