.container {
  display: flex;
  flex-direction: column;

  background: #210303;
  border-top: 2px solid #6d1717;

  pointer-events: all;

  > hr {
    margin-top: 3px;

    border: none;
    border-bottom: 1px solid #6d1717;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
}

.left,
.right {
  --height: calc(8px + var(--hud-bar-bottom-size));
  --width: calc(6.421875 * var(--height));
  --side-offset: calc(-0.5 * var(--width));

  height: var(--height);
  width: var(--width);

  transform: scaleY(-1);
  filter: FlipV;

  /* top should protrude out of bounds slightly */
  margin-top: -14px;

  background-image: url(../../../../assets/images/game/hud/protruding-panel.png);
  background-size: 100% 100%;
}

.left {
  margin-left: var(--side-offset);
}

.right {
  margin-right: var(--side-offset);
}
