.container {
  --border-3d-width: 2px;
  --border-3d-light-color: #6B5455;
  --border-3d-dark-color: #462E2F;
  --outline-width: 2.5px;

  flex-shrink: 0;
  position: relative;
  height: 57px;
  width: 53px;
  padding: 2px;
  margin: var(--outline-width);

  background-color: #564041;
  border-radius: 2px;
  box-shadow: 0 0 0 var(--outline-width) #2A1C1B;

  transition: background-color var(--ui-trans-time);
}

.img-container {
  position: relative;
  height: 100%;
}

.level-up-img {
  --size: 20px;
  --offset: calc(-1 * var(--border-3d-width));

  position: absolute;
  right: var(--offset);
  bottom: var(--offset);
  height: var(--size);
  width: var(--size);
  object-fit: contain;
}
