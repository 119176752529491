.container {
  padding: 2px !important;
}

.plaque {
  --border-3d-width: 1px;
  --border-3d-light-color: #555453;
  --border-3d-dark-color: #383735;
  --outline-width: 1px;

  position: relative;
  margin: var(--outline-width);
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 2px;
  box-shadow: 0 0 0 var(--outline-width) #2B2B2B;

  /* title */
  > span {
    font-weight: 700;
    font-size: 31px;
    line-height: 33px;
    text-transform: uppercase;
    color: #DBDBDB;
    user-select: none;
  }
}
